import {requests} from 'requests';
import {Organization} from 'models/organization';

/**
 * User model
 */
export class User {
    private static instance: User | null = null;

    _id: string;
    name: string;
    username: string;
    organization: Organization[] = [];
    selectedOrganizationID: string = '';
    passwordChanged: boolean = false;

    // auth
    private _token: string = '';

    private constructor(data: any) {
        this._id = data?.id;
        this.name = data?.name;
        this.username = data?.username;
        this.organization = data?.organization ? data.organization.map((org: any) => new Organization(org)) : [];
        // If orgs are available, set the selected org to the first one
        this.selectedOrganizationID = data?.selectedOrganizationID || this.organization[0]?._id || '';
        this.passwordChanged = data?.passwordChanged || false;
    }

    /**
     * Get the singleton instance of the User class.
     * If the instance doesn't exist, it will be initialized from local storage.
     * @returns {User} The User instance.
     */
    static getInstance(): User {
        if (!User.instance) {
            // Try to retrieve the user data from local storage
            User.instance = new User({});
            const userData = localStorage.getItem('user');
            if (userData) {
                const data = JSON.parse(userData);
                User.instance = new User(data);
                // set the tokens and expiration
                User.instance._token = data._token || '';
            }
        }

        const urlParams = new URLSearchParams(window.location.search);
        // Handle the token in the url
        if (urlParams.get('_token') !== null) {
            User.instance._token = urlParams.get('_token') as string;
            User.instance.saveToLocalStorage();
        }
        return User.instance;
    }

    /**
     * Save the user data to local storage.
     */
    private saveToLocalStorage(): void {
        const userData = JSON.stringify(this);
        localStorage.setItem('user', userData);
    }

    /**
     * Clear the user data from local storage and reset the instance.
     */
    reset(): void {
        localStorage.removeItem('user');
        this._id = '';
        this.name = '';
        this.username = '';
        this.organization = [];
        this.selectedOrganizationID = '';
        this._token = '';
        this.passwordChanged = false;
    }

    /**
     * Update the user's profile
     */
    async updateSelf(updatedUser: any): Promise<User> {
        // todo: update the user
        return this;
    }

    /**
     * Fetch the user's profile
     * @returns {Promise<User>} the user
     */
    async fetchSelf(): Promise<User> {
        const u = await requests.user.get();
        this._id = u._id;
        this.name = u?.name;
        this.username = u?.username;
        this.passwordChanged = u?.passwordChanged || false;
        this.organization = u?.organization ? u.organization.map((org: any) => new Organization(org)) : [];
        // Check if the selected organization ID is set and is from the list of organizations or set it to the first organization
        this.selectedOrganizationID = this.getSelectedOrganizationID();

        this.saveToLocalStorage();

        return this;
    }

    /**
     * Get the user's full name
     * @returns {string} The user's full name
     * @memberof User
     * @description
     */
    getFullName(): string {
        return this.name;
    }

    /**
     * Is the user logged in?
     * Return true if refresh token has not expired or the value is 0
     * @returns {boolean} true if the user is logged in
     */
    isLoggedin(): boolean {
        // if the token is expired, the user is not logged in
        if (this._token === '' || this._token === undefined || this._token === null) return false;

        return true;
    }

    /**
     * Is the token expired?
     * @returns {boolean} true if the token is expired
     */
    isTokenExpired(offset = 0): boolean {
        // todo: implement token expiration in the api
        if (this._token === '' || this._token === undefined || this._token === null) return true;
        return false;
    }

    /**
     * Get the auth token
     * @returns {string} the auth token
     * @memberof User
     * @description
     * The token is only returned if it is not expired.
     * If the token is expired, an empty string is returned.
     */
    getToken(): string {
        if (this.isTokenExpired()) return '';
        return this._token;
    }

    /**
     * Refresh the auth token
     * @returns {Promise<boolean>} true if the token was refreshed
     * @memberof User
     * @description
     * The token is only refreshed if it is expired.
     * If the token is not expired, false is returned.
     * If the token is refreshed, true is returned.
     */
    async refreshToken(): Promise<boolean> {
        // todo: implement this in the api
        return false;
    }

    /**
     * Log in the user
     * @param username
     * @param password
     */
    async login(username: string, password: string): Promise<boolean> {
        const result = await requests.auth.login(username, password);
        if (result) {
            this._token = result.token;

            this.saveToLocalStorage();
        }
        return result !== null;
    }

    /**
     * Log in with a token
     */
    async loginWithToken(token: string): Promise<boolean> {
        if (!token) {
            return false;
        }

        this._token = token;
        this.saveToLocalStorage();

        // Fetch the user's data
        try {
            await this.fetchSelf();
        } catch (e) {
            throw e;
        }
        return true;
    }

    /**
     * Log out the user
     */
    async logout(): Promise<boolean> {
        // Clear the tokens irrespective of the logout result for security
        this._token = '';
        this.name = '';
        this.username = '';
        this.organization = [];
        this.selectedOrganizationID = '';

        this.saveToLocalStorage();

        try {
            // Send the logout request to invalidate the refresh token
            const result = await requests.auth.logout(this._token);
            if (!result) {
                // todo: remvove this and show a message to the user
                console.error('Error logging out', result);
            }
        } catch (error) {
            console.error('Error logging out', error);
        }

        return true;
    }

    /**
     * Update the user's password
     */
    async updatePassword(newPassword: string): Promise<boolean> {
        const result = await requests.user.updatePassword(newPassword);
        if (result) {
            this.passwordChanged = true;
            this.saveToLocalStorage();
        }
        return result;
    }

    /**
     * Handle forgot password
     * @param username
     */
    async forgotPassword(username: string): Promise<boolean> {
        // todo: implement this in the api
        return false;
    }

    /**
     * Reset password
     * @param username
     * @param newPassword
     * @param code
     */
    async resetPassword(username: string, newPassword: string, code: string): Promise<boolean> {
        // todo: implement this in the api
        return false;
    }

    /**
     * Check if password is changed
     */
    isPasswordChanged(): boolean {
        return this.passwordChanged;
    }

    /**
     * Get selected organization
     * @returns {Organization} the selected organization
     */
    getSelectedOrganization(): Organization {
        return this.organization.find(org => org._id === this.selectedOrganizationID) as Organization;
    }

    /**
     * Get selected organization ID
     * @returns {string} the selected organization ID
     */
    getSelectedOrganizationID(): string {
        // Check if the selected organization ID is set
        if (!this.selectedOrganizationID) {
            // If not, set it to the first organization
            this.selectedOrganizationID = this?.organization[0]?._id || '';
            this.saveToLocalStorage();
        }

        // Verify that the selected organization ID is from the list of organizations
        const org = this.organization.find(org => org._id === this.selectedOrganizationID);

        // if not, set it to the first organization
        if (!org) {
            this.selectedOrganizationID = this?.organization[0]?._id || '';
            this.saveToLocalStorage();
        }

        return this.selectedOrganizationID;
    }

    /**
     * Set the selected organization
     * @param {string} organizationID the organization ID
     */
    setSelectedOrganization(organizationID: string): void {
        // Validate the organization ID
        const org = this.organization.find(org => org._id === organizationID);
        if (!org) throw new Error('Invalid organization ID');

        this.selectedOrganizationID = organizationID;
        this.saveToLocalStorage();
    }

    /**
     * Get organisation list
     */
    getOrganizationList(): Organization[] {
        return this.organization;
    }

    /**
     * Is admin of the selected organization?
     */
    isCurrentOrgAdmin(): boolean {
        const currentOrg = this.getSelectedOrganization();
        const currentOrgAdmin = currentOrg?.admin;

        const isAdmin = false;

        if (currentOrgAdmin === this._id) {
            return true;
        }

        return isAdmin;
    }
}
