import {auth} from './auth';
import {templates} from './templates';
import {sections} from './sections';
import {writingRules} from './writingRules';
import {user} from './user';
import {pseudo} from './pseudo';

export const requests = {
    auth,
    templates,
    sections,
    writingRules,
    user,
    pseudo,
};
