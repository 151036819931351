import {v4 as uuidv4} from 'uuid';
import {Source} from 'models/source';

export class Operation {
    // For the frontend so that we can easily add and remove operations irrespective of their state in the database
    key: string;

    // The section id
    op: any;

    name: string;
    sources: Source[];

    constructor(data: any) {
        this.key = data.key;
        this.op = data.op;
        this.name = data.name;
        this.sources = data.sources ? data.sources.map((source: any) => new Source(source)) : [];

        // If the section doesn't have a key, generate a key
        if (!this.key) {
            this.generateKey();
        }
    }

    getID() {
        return this.op;
    }

    setID(id: any) {
        this.op = id;
    }

    runBody() {
        return {
            op: this.op,
            // array of source values
            elements: this.sources.map(source => source.value),
        };
    }

    private generateKey() {
        if (!this.key) {
            this.key = uuidv4();
        }
    }
}
