import {requests} from 'requests';
import {Plan} from 'models/plan';
import {TeamMember} from 'models/teamMember';
import {count} from 'console';

export interface Usage {
    usedTokens: number;
    availableTokens: number;
    maxTokens: number;
    tokenLimitReached?: boolean;

    usedTemplates: number;
    availableTemplates: number;
    maxTemplates: number;
    templateLimitReached?: boolean;

    usedSeats: number;
    availableSeats: number;
    maxSeats: number;
    seatLimitReached?: boolean;

    usedRules: number;
    availableRules: number;
    maxRules: number;
    ruleLimitReached?: boolean;
}

export class Organization {
    _id: any;
    name: string;
    plan: Plan;
    tokenCount: number;
    rules: number;
    admin: string;
    templateCount: number;
    dateCreated: Date;
    users: TeamMember[] = [];
    trialStartDate: Date;
    trialEndDate: Date;
    trialTokens: number;
    trialEnded: boolean;
    upcomingPlan: string;

    constructor(data: any) {
        this._id = data._id;
        this.name = data.name;
        this.plan = data.plan ? new Plan(data.plan) : new Plan({});
        this.tokenCount = data.tokenCount;
        this.rules = data.rules;
        this.admin = data.admin;
        this.templateCount = data.templateCount;
        this.dateCreated = data.dateCreated;
        this.users = data.users ? data.users.map((user: any) => new TeamMember(user)) : [];
        this.trialStartDate = data.trialStartDate;
        this.trialEndDate = data.trialEndDate;
        this.trialTokens = data.trialTokens;
        this.trialEnded = data.trialEnded;
        this.upcomingPlan = data.upcomingPlan;
    }

    getID() {
        return this._id;
    }

    setID(id: any) {
        this._id = id;
    }

    getPlan() {
        return this.plan;
    }

    getPlanName() {
        return this?.plan?.name;
    }

    isPlanCancelled() {
        return this.getPlanName().toLowerCase() === 'cancelled';
    }

    isOnTrialPlan() {
        return !this?.trialEnded || this?.trialEndDate > new Date();
    }

    getTrialEndDate() {
        return this?.trialEndDate;
    }

    getTrialStartDate() {
        return this?.trialStartDate;
    }

    getTrialTokens() {
        return this?.trialTokens;
    }

    getUsage(): Usage {
        return {
            usedTokens: this.tokenCount,
            availableTokens: this.plan.tokenLimit - this.tokenCount,
            maxTokens: this.plan.tokenLimit,
            tokenLimitReached: this.tokenCount >= this.plan.tokenLimit,

            usedTemplates: this.templateCount,
            availableTemplates: this.plan.templateLimit - this.templateCount,
            maxTemplates: this.plan.templateLimit,
            templateLimitReached: this.templateCount >= this.plan.templateLimit,

            usedSeats: this.users.length,
            availableSeats: this.plan.seatLimit - this.users.length,
            maxSeats: this.plan.seatLimit,
            seatLimitReached: this.users.length >= this.plan.seatLimit,

            usedRules: this.rules,
            availableRules: this.plan.ruleLimit - this.rules,
            maxRules: this.plan.ruleLimit,
            ruleLimitReached: this.rules >= this.plan.ruleLimit,
        };
    }
}
