import {requests} from 'requests';

export class WritingRule {
    _id: any;
    name: string;
    owner: string;
    prompt: string;
    dateCreated: Date;
    isExample: boolean = false;

    constructor(data: any) {
        this._id = data._id;
        this.name = data.name;
        this.owner = data.owner;
        this.prompt = data.prompt;
        this.dateCreated = data.dateCreated;
        this.isExample = data.isExample;
    }

    createBody() {
        return {
            name: this.name,
            prompt: this.prompt,
        };
    }

    getID() {
        return this._id;
    }

    setID(id: any) {
        this._id = id;
    }

    async createInApi() {
        try {
            const response: any = await requests.writingRules.create(this);
            return this;
        } catch (e: any) {
            throw new Error(e);
        }
    }

    async updateInApi() {
        try {
            const response: any = await requests.writingRules.update(this);
            return this;
        } catch (e: any) {
            throw new Error(e);
        }
    }

    updateBody() {
        return {
            name: this.name,
            prompt: this.prompt,
        };
    }

    async apply(text: string) {
        try {
            const response: any = await requests.writingRules.run(this._id, text);
            return response;
        } catch (e: any) {
            throw new Error(e);
        }
    }
}
