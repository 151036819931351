import {Operation} from 'models/operation';
import {requests} from 'requests';

export class Template {
    _id: string;
    name: string;
    owner: string;
    dateCreated: Date;
    isExample: boolean;
    operations: Operation[];

    constructor(data: any) {
        this._id = data._id;
        this.name = data.name;
        this.owner = data.owner;
        this.dateCreated = data.dateCreated;
        this.isExample = data.isExample ? data.isExample : false;
        this.operations = data.operations ? data.operations.map((operation: any) => new Operation(operation)) : [];
    }

    getID() {
        return this._id;
    }

    createBody() {
        return {
            name: this.name,
            isExample: this.isExample,

            // [{op: string}] where op is the operation id
            operations: this.operations.map(operation => {
                return {
                    op: operation.getID(),
                };
            }),
        };
    }

    async createInApi() {
        try {
            const response: any = await requests.templates.create(this);
            this._id = response._id;
            return this;
        } catch (e: any) {
            throw new Error(e);
        }
    }

    updateBody() {
        return {
            name: this.name,
            isExample: this.isExample,

            // [{op: string}] where op is the operation id
            operations: this.operations.map(operation => {
                return {
                    op: operation.getID(),
                };
            }),
        };
    }

    async updateInApi() {
        try {
            const response: any = await requests.templates.update(this);
            return this;
        } catch (e: any) {
            throw new Error(e);
        }
    }

    runBody() {
        return {
            operations: this.operations.map(operation => operation.runBody()),
        };
    }
}
