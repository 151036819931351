import React, { ReactNode } from 'react';
import Alert from '@mui/material/Alert';
import Slide from '@mui/material/Slide';
import Snackbar from '@mui/material/Snackbar';

// Define the interface for the props
export interface CustomSnackbarProps {
    open?: boolean;
    autoHideDuration?: number;
    onClose?: () => void;
    vertical?: 'top' | 'bottom';
    horizontal?: 'center' | 'left' | 'right';
    message?: string;
    severity?: 'success' | 'error' | 'info' | 'warning';
    transitionDirection?: 'up' | 'down' | 'left' | 'right';
}

const CustomSnackbar = ({
    open = false,
    autoHideDuration = 5000,
    onClose = () => {},
    vertical = 'top' as 'top' | 'bottom',
    horizontal = 'center' as 'center' | 'left' | 'right',
    message = '' as string,
    severity = 'success' as 'success' | 'error' | 'info' | 'warning',
    transitionDirection = 'down' as 'up' | 'down' | 'left' | 'right',
}) => {
    return (
        <Snackbar
            open={open}
            autoHideDuration={autoHideDuration}
            onClose={onClose}
            anchorOrigin={{vertical, horizontal}}
            TransitionComponent={props => <Slide {...props} direction={transitionDirection} />}>
            <Alert severity={severity} sx={{width: '100%'}}>
                {message}
            </Alert>
        </Snackbar>
    );
};

export default CustomSnackbar;
