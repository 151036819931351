import {apiRequest, authenticatedApiRequest, APIRequestType, APIResponse} from 'utils/api';
import {Section} from 'models/section';

export const sections = {
    // List all section
    async list(): Promise<Array<Section>> {
        const response = await authenticatedApiRequest(APIRequestType.GET, 'tools/section');

        if (response.ok()) {
            let res = response.body();
            return res.map((section: any) => new Section(section));
        }
        throw new Error(response.getError());
    },

    // Get a section by id
    async get(id: string): Promise<any> {
        const response = await authenticatedApiRequest(APIRequestType.GET, `tools/section/${id}`);

        if (response.ok()) {
            return response.body();
        }
        throw new Error(response.getError());
    },

    // Create a section
    async create(section: Section): Promise<any> {
        const response = await authenticatedApiRequest(APIRequestType.POST, 'tools/section', {data: section.createBody()});

        if (response.ok()) {
            return response.body();
        }
        throw new Error(response.getError());
    },

    // Update a section
    async update(section: Section): Promise<any> {
        const response = await authenticatedApiRequest(APIRequestType.PATCH, `tools/section/${section._id}`, {data: section.updateBody()});

        if (response.ok()) {
            return response.body();
        }
        throw new Error(response.getError());
    },

    // Delete a section
    async delete(id: string): Promise<any> {
        const response = await authenticatedApiRequest(APIRequestType.DELETE, `tools/section/${id}`);

        if (response.ok()) {
            return response.body();
        }

        throw new Error(response.getError());
    },

    // Test an unsaved section
    async testUnsaved(example: string, prompt: string, text: string): Promise<any> {
        const response = await authenticatedApiRequest(APIRequestType.POST, `tools/section/run`, {data: {example, prompt, text}});

        if (response.ok()) {
            return response.body();
        }

        throw new Error(response.getError());
    },
};
