import {apiRequest, authenticatedApiRequest, APIRequestType, APIResponse} from 'utils/api';
import {WritingRule} from 'models/writingRule';

export const writingRules = {
    // List all writingRules
    async list(): Promise<Array<WritingRule>> {
        const response = await authenticatedApiRequest(APIRequestType.GET, 'rules');

        if (response.ok()) {
            let res = response.body();
            return res.map((writingRule: any) => new WritingRule(writingRule));
        }
        throw new Error(response.getError());
    },

    // Get a writingRule by id
    async get(id: string): Promise<any> {
        const response = await authenticatedApiRequest(APIRequestType.GET, `rules/${id}`);

        if (response.ok()) {
            return response.body();
        }
        throw new Error(response.getError());
    },

    // Create a writingRule
    async create(writingRule: WritingRule): Promise<any> {
        const response = await authenticatedApiRequest(APIRequestType.POST, 'rules', {data: writingRule.createBody()});

        if (response.ok()) {
            return response.body();
        }
        throw new Error(response.getError());
    },

    // Update a writingRule
    async update(writingRule: WritingRule): Promise<any> {
        const response = await authenticatedApiRequest(APIRequestType.PATCH, `rules/${writingRule._id}`, {data: writingRule.updateBody()});

        if (response.ok()) {
            return response.body();
        }
        throw new Error(response.getError());
    },

    // Delete a writingRule
    async delete(id: string): Promise<any> {
        const response = await authenticatedApiRequest(APIRequestType.DELETE, `rules/${id}`);

        if (response.ok()) {
            return response.body();
        }

        throw new Error(response.getError());
    },

    // Run a writingRule
    async run(ruleId: string, text: string): Promise<any> {
        const response = await authenticatedApiRequest(APIRequestType.POST, `rules/run/${ruleId}`, {
            data: {
                text: text,
            },
        });

        if (response.ok()) {
            return response.body();
        }

        throw new Error(response.getError());
    },

    // Run unsaved writingRule
    async runUnsaved(prompt: string, text: string): Promise<any> {
        const response = await authenticatedApiRequest(APIRequestType.POST, `rules/run`, {
            data: {
                prompt: prompt,
                text: text,
            },
        });

        if (response.ok()) {
            return response.body();
        }

        throw new Error(response.getError());
    },
};
