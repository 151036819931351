export const showGreeting = (name: string, time = new Date()) => {
    let greeting = '👋 Hello';

    const hour = time.getHours();

    if (hour >= 6 && hour < 12) {
        greeting = '🌅 Good morning';
    } else if (hour >= 12 && hour < 18) {
        greeting = '👋 Good afternoon';
    } else if (hour >= 18 && hour < 23) {
        greeting = '🌇 Good evening';
    }

    if (name) {
        greeting += `, ${name}`;
    }

    return greeting;
};

// a little function to help us with reordering the result
export const reorder = <T>(list: T[], startIndex: number, endIndex: number): T[] => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

export function truncateString(str: string, num: number): string {
    if (str.length <= num) {
        return str;
    }
    return str.slice(0, num) + '...';
}

export function formatNumber(num: number, {prefix = '', suffix = '', decimals = 2}: {prefix?: string; suffix?: string; decimals?: number}): string {
    // return in case of 0
    if (num === 0) {
        return `${prefix}0${suffix}`;
    }

    // Adjust for negative numbers
    const isNegative = num < 0;
    if (isNegative) {
        num = Math.abs(num);
    }

    const lookup = [
        {value: 1, symbol: ''},
        {value: 1e3, symbol: 'K'},
        {value: 1e6, symbol: 'M'},
        {value: 1e9, symbol: 'B'},
        {value: 1e12, symbol: 'T'},
        {value: 1e15, symbol: 'P'}, // Using 'P' for quadrillion
        {value: 1e18, symbol: 'Q'},
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    let result = '';
    for (let i = lookup.length - 1; i >= 0; i--) {
        if (num >= lookup[i].value) {
            result = (num / lookup[i].value).toFixed(decimals).replace(rx, '$1') + lookup[i].symbol;
            break;
        }
    }
    return `${prefix}${isNegative ? '-' : ''}${result}${suffix}`;
}

// Take a word and a number and return the pluralized word
export function pluralize(word: string, count: number): string {
    return count === 1 ? word : word + 's';
}

// String conversion functions
export function toTitleCase(str: string): string {
    return str.replace(/\w\S*/g, txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
}

export function toSentenceCase(str: string): string {
    return str.charAt(0).toUpperCase() + str.substr(1).toLowerCase();
}

// Get the formatted page title
// Example: getFormattedPageTitle('Dashboard') => 'Dashboard | App Name'
export function getFormattedPageTitle(pageTitle: string): string {
    return process.env.REACT_APP_NAME ? `${pageTitle} | ${process.env.REACT_APP_NAME}` : pageTitle;
}
