import {apiRequest, authenticatedApiRequest, APIRequestType, APIResponse} from 'utils/api';

export const user = {
    async signup(name: string, username: string, password: string): Promise<any> {
        const response = await apiRequest(APIRequestType.POST, '/user/register', JSON.stringify({name, username, password}));
        if (response.ok()) {
            return response.body();
        }
        throw new Error(response.getError());
    },

    // Get the user's details
    async get(): Promise<any> {
        const response = await authenticatedApiRequest(APIRequestType.GET, 'user/details');

        if (response.ok()) {
            return response.body();
        }
        throw new Error(response.getError());
    },

    // Update user password
    async updatePassword(newPassword: string): Promise<boolean> {
        const response = await authenticatedApiRequest(APIRequestType.PATCH, 'user/update-password', {
            data: {
                password: newPassword,
            },
        });

        if (response.ok()) {
            return true;
        }
        throw new Error(response.getError());
    },

    // customer portal
    async getCustomerPortal(): Promise<any> {
        const response = await authenticatedApiRequest(APIRequestType.GET, 'user/customer-portal');

        if (response.ok()) {
            return response.body();
        }
        throw new Error(response.getError());
    },

    // invite user
    async inviteUser(email: string): Promise<any> {
        const response = await authenticatedApiRequest(APIRequestType.POST, 'user/invite', {
            data: {
                email,
            },
        });

        if (response.ok()) {
            return response.body();
        }
        throw new Error(response.getError());
    },

    // accept invite
    async acceptInvite(data: any, invitationToken: string): Promise<any> {
        const response = await apiRequest(APIRequestType.PATCH, 'user/accept-invitation', JSON.stringify(data), {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Invitation": `Bearer ${invitationToken}`,
        });
        if (response.ok()) {
            return response.body();
        }
        throw new Error(response.getError());
    },

    // forgot password
    async forgotPassword(email: string): Promise<any> {
        const response = await apiRequest(APIRequestType.POST, 'user/forgot-password', JSON.stringify({email}));
        if (response.ok()) {
            return response.body();
        }
        throw new Error(response.getError());
    },

    // reset password
    async resetPassword(data: any, resetToken: string): Promise<any> {
        const response = await apiRequest(APIRequestType.PATCH, 'user/reset-password', JSON.stringify(data), {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "ResetToken": `Bearer ${resetToken}`,
        });
        if (response.ok()) {
            return response.body();
        }
        throw new Error(response.getError());
    },

    async checkoutStatus(checkout_session_id: string): Promise<any> {
        const response = await apiRequest(APIRequestType.POST, `/user/checkout-status/${checkout_session_id}`);
        if (response.ok()) {
            return response.body();
        }
        throw new Error(response.getError());
    },

    async verifyEmail(token: string): Promise<any> {
        const response = await apiRequest(APIRequestType.POST, `/user/verify-email/`, JSON.stringify({}), {
            'Verification': `Bearer ${token}`,
        });
        if (response.ok()) {
            return response.body();
        }
        throw new Error(response.getError());
    }
};
