import {ErrorResponse} from './error';
import {User} from 'models/user';

export enum APIRequestType {
    GET = 'GET',
    POST = 'POST',
    PUT = 'PUT',
    DELETE = 'DELETE',
    PATCH = 'PATCH',
}

/**
 * API response
 * @class
 * @param {number} status the HTTP status code
 * @param {any} data the response data
 * @param {any} error the error object
 * @param {string} errorMsg the error message
 * @description
 * The API response is a wrapper around the response data and error.
 */
export class APIResponse {
    status: number;
    data: any;
    error: ErrorResponse;

    constructor(status: number, data: any, error: any) {
        this.status = status;
        this.data = data;
        this.error = new ErrorResponse(error);
    }

    ok(): boolean {
        return this.status >= 200 && this.status < 300;
    }

    errNotFound(): boolean {
        return this.status === 404;
    }

    getError(): any {
        return this.error;
    }

    getErrorMsg(): string {
        return this.error.getErrorMsg();
    }

    toModel<T>(model: new (data: any) => T): T {
        return new model(this.data);
    }

    requiresLogin(): boolean {
        return this.status === 401;
    }

    body(): any {
        return this.data;
    }
}

/**
 * Make a request to the API
 */
export async function apiRequest(method: APIRequestType, path: string, data?: string, headers?: any, query?: any): Promise<APIResponse> {
    // get the admin API URL from the path
    let ADMIN_API_URL = process.env.REACT_APP_API_BASE_URL;

    if (!path.startsWith('/')) {
        path = '/' + path;
    }

    // When adminRequest is called, the headers are not set. Eg: Login, signup, etc.
    if (!headers) {
        headers = {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        };
    }

    return fetch(`${ADMIN_API_URL}${path}` + (query ? `?${new URLSearchParams(query)}` : ''), {
        method,
        headers: headers,
        body: data,
    })
        .then(async res => {
            if (res.ok) {
                if (res.status === 204) {
                    return new APIResponse(res.status, null, null);
                }
                return new APIResponse(res.status, await res.json(), null);
            }

            // if result is 401, then logout the user
            if (res.status === 401) {
                const user = User.getInstance();
                user.logout();
            }

            return new APIResponse(res.status, null, await res.json());
        })
        .catch(err => {
            return new APIResponse(500, null, err);
        });
}

/**
 * Make a request to the API server using the User object.
 * @param {User} user the user object
 * @param {APIRequestType} method the HTTP method
 * @param {string} path the path
 * @param {any} [data] the request data
 * @param {any} [headers] the request headers
 * @param {any} [query] the query parameters
 * @returns {Promise<any>} the response
 */
export async function authenticatedApiRequest(
    method: APIRequestType | string,
    path: string,
    {data, headers, query}: {data?: any; headers?: any; query?: any} = {},
): Promise<APIResponse> {
    // get the user
    const user = User.getInstance();

    if (!user) return Promise.resolve(new APIResponse(401, null, null));

    // check if the user is logged in
    if (!user.isLoggedin()) {
        return Promise.resolve(new APIResponse(401, null, null));
    }

    // get the token
    let token = user.getToken();
    if (!token) {
        // refresh the token
        await user.refreshToken();
        token = user.getToken();
    }

    if (!headers) headers = {};

    // add token to the headers
    headers['Authorization'] = `Bearer ${token}`;

    const selectedOrganizationID = user.getSelectedOrganizationID();

    if (selectedOrganizationID) {
        // add Organization ID to the headers
        headers['Organization'] = selectedOrganizationID;
    }

    if (data) {
        headers['Accept'] = 'application/json';
        if (!(data instanceof FormData)) {
            headers['Content-Type'] = 'application/json';
            data = JSON.stringify(data);
        }
    }

    return apiRequest(method as APIRequestType, path, data, headers, query);
}
