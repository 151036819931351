/**
 * Error response from the API
 */
export class ErrorResponse {
    err: string = 'Something went wrong. Please try again later.';

    constructor(error: any) {
        if (error) {
            this.err = error.err || 'Something went wrong. Please try again later.';
        }
    }

    getErrorMsg(): string {
        return this.err;
    }
}
