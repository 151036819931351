import type {LoaderFunctionArgs} from 'react-router-dom';
import {redirect} from 'react-router-dom';
import {User} from 'models/user';

interface AuthProvider {
    isAuthenticated: boolean;
    user: User;
    signin(username: string, password: string): Promise<Boolean>;
    signout(): Promise<Boolean>;
}

/**
 * This represents some generic auth provider API, like Firebase.
 */
export const AuthProvider: AuthProvider = {
    get isAuthenticated() {
        return User.getInstance().isLoggedin();
    },
    get user() {
        return User.getInstance();
    },
    async signin(username: string, password: string) {
        const user = User.getInstance();

        // reset the user
        user.reset();

        try {
            // log the user in
            const result = await user.login(username, password);

            // if the login failed, return false
            if (!result) {
                return false;
            }
            console.log('1');
            // fetch the user's data
            await user.fetchSelf();
            console.log('2');

            // save the user to local storage
            return true;
        } catch (e) {
            console.log(e);
            console.log('3');
            throw e;
        }
    },
    async signout() {
        return await User.getInstance().logout();
    },
};

export function protectedLoader({request}: LoaderFunctionArgs) {
    // If the user is not logged in and tries to access `/protected`, we redirect
    // them to `/login` with a `from` parameter that allows login to redirect back
    // to this page upon successful authentication
    if (!AuthProvider.isAuthenticated) {
        let params = new URLSearchParams();
        params.set('from', new URL(request.url).pathname);
        return redirect('/login?' + params.toString());
    }
    return {user: AuthProvider.user};
}

export async function loginLoader() {
    if (AuthProvider.isAuthenticated) {
        return redirect('/my-templates');
    }
    return null;
}
